import enhanceAxios from "./enhanceAxios";
import validateParams from "./server/validation";
import {
  name as packageName,
  version as packageVersion
} from "../package.json";
import uuid from "uuid/v4";
import axios from "axios";
const { CancelToken, isCancel } = axios;

const SUPPORTED_HTTP_METHODS = ["get", "post", "delete", "patch", "put"];
const ASOS_CORRELATION_HEADER = "asos-cid";
const ASOS_SESSION_CORRELATION_HEADER = "asos-sid";
const ASOS_CLIENT_NAME_HEADER = "asos-c-name";
const ASOS_CLIENT_VERSION_HEADER = "asos-c-ver";
const ASOS_CLIENT_PLATFORM_HEADER = "asos-c-plat";
const USER_AGENT_HEADER = "user-agent";
const DEFAULT_PLATFORM = "web";
const CONTENT_TYPE_HEADER = ["Content-Type", "content-type"];

const configureRequest = ({
  defaultTimeout,
  retries = 0,
  logger,
  serverRequestLogger,
  httpsAgent,
  retryDelay,
  retryCondition,
  shouldResetTimeout,
  client,
  getSid,
  headerLoggingExcludes,
  validateStatus = status => status >= 200 && status < 300 // Axios' internal utils.merge is broken and merges undefined over defaults, should be fixed in >0.18 by https://github.com/axios/axios/pull/1395
}) => {
  if (SERVER) {
    validateParams({ logger, client });
  }
  const { name, version, platform = DEFAULT_PLATFORM } = client;
  const defaultValidateStatus = validateStatus;
  const axiosInstance = enhanceAxios({
    retries,
    logger,
    serverRequestLogger,
    httpsAgent,
    retryDelay,
    retryCondition,
    shouldResetTimeout,
    headerLoggingExcludes
  });

  const setCustomUserAgentHeader = headers => {
    headers[USER_AGENT_HEADER] = [
      packageName,
      packageVersion,
      name,
      platform,
      version
    ].join("/");
  };

  const trySetAcceptEncoding = (headers, setAcceptEncoding) => {
    if (setAcceptEncoding) {
      headers["accept-encoding"] = "gzip, deflate";
    }
  };

  const trySetAsosSessionIdHeader = headers => {
    if (typeof getSid === "function") {
      headers[ASOS_SESSION_CORRELATION_HEADER] = getSid();
    }
  };

  const addStandardHeaders = ({ headers, cid, setAcceptEncoding }) => {
    if (!SERVER) {
      trySetAsosSessionIdHeader(headers);
    } else {
      setCustomUserAgentHeader(headers);
      trySetAcceptEncoding(headers, setAcceptEncoding);
    }

    headers[ASOS_CORRELATION_HEADER] = cid || uuid();
    headers[ASOS_CLIENT_PLATFORM_HEADER] = platform;
    headers[ASOS_CLIENT_NAME_HEADER] = name;
    headers[ASOS_CLIENT_VERSION_HEADER] = version;
  };

  const getData = (headers, body) => {
    if (body === undefined) {
      return undefined;
    }
    const isXFormUrlEncodedRequest =
      !!headers &&
      !!CONTENT_TYPE_HEADER.find(
        contentTypeHeader =>
          headers[contentTypeHeader] === "application/x-www-form-urlencoded"
      );

    if (isXFormUrlEncodedRequest) {
      return body;
    }

    return JSON.stringify(body);
  };

  const request =
    method =>
    ({
      url,
      uuid: serverUuid,
      cid,
      params,
      maxRedirects,
      body,
      headers = {},
      timeout = defaultTimeout,
      additionalLoggingParams = {},
      cancelTokenExecutor,
      validateStatus = defaultValidateStatus,
      setAcceptEncoding = true
    }) => {
      addStandardHeaders({ headers, cid, setAcceptEncoding });

      return axiosInstance({
        method,
        headers,
        url,
        params,
        maxRedirects,
        data: getData(headers, body),
        timeout,
        asosConfig: { ...additionalLoggingParams, serverUuid },
        cancelToken: cancelTokenExecutor
          ? new CancelToken(cancelTokenExecutor)
          : undefined,
        validateStatus
      });
    };

  return SUPPORTED_HTTP_METHODS.reduce((methods, method) => {
    methods[method] = request(method);
    return methods;
  }, {});
};

const isCancelled = isCancel;

export { configureRequest, isCancelled };
